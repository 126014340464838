import React, { createContext, useState, useContext } from 'react';

// Create the Context
const MenuContext = createContext();

// Create the Provider
export const MenuProvider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);
  const handleSetActive = (section) => {
    setActiveSection(section);
    setIsMenuOpen(false);
  };

  return (
    <MenuContext.Provider value={{ isMenuOpen, activeSection, toggleMenu, handleSetActive }}>
      {children}
    </MenuContext.Provider>
  );
};

// Custom Hook for consuming the context
export const useMenuContext = () => useContext(MenuContext);
