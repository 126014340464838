import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MenuProvider } from './Static/MenuContext';
import Home from './Pages/Home';
import Header from "./Static/Header";
import Footer from "./Static/Footer";
import ImageCategory1 from "./Pages/ImageCategory1";
import ImageCategory2 from "./Pages/ImageCategory2";
import ImageCategory3 from "./Pages/ImageCategory3";
import ImageCategory4 from "./Pages/ImageCategory4";
import ImageCategory5 from "./Pages/ImageCategory5";
import ImageCategory6 from "./Pages/ImageCategory6";
import ImageCategory7 from "./Pages/ImageCategory7";
import FormComponent from "./Static/FormComponent";
import CompanyDetails from "./Static/CompanyDetails";
import Work from "./Pages/Work";

const AppRoutes = () => {
    return (
        <MenuProvider>
        <Router>
            <Header />
            <Routes>
                {/* Home Route */}
                <Route exact path="/" element={<Home />} />
                <Route path='/' element={<Work/>}/>
                {/* Image Category Routes */}
                <Route path="/imagecategory1/:imageName" element={<ImageCategory1 />} />
                <Route path="/imagecategory2/:imageName" element={<ImageCategory2 />} />
                <Route path="/imagecategory3/:imageName" element={<ImageCategory3 />} />
                <Route path="/imagecategory4/:imageName" element={<ImageCategory4 />} />
                <Route path="/imagecategory5/:imageName" element={<ImageCategory5 />} />
                <Route path="/imagecategory6/:imageName" element={<ImageCategory6 />} />
                <Route path="/imagecategory7/:imageName" element={<ImageCategory7 />} />

                {/* Form and Company Details Routes */}
                <Route path="/form" element={<FormComponent />} />
                <Route path="/details" element={<CompanyDetails />} />
            </Routes>
            <Footer />
        </Router>
        </MenuProvider>
    );
}

export default AppRoutes;