import React, { useEffect } from "react";
import img1 from "../image/Work 1.png";
import img2 from "../image/Logo 7.png";
import img3 from "../image/Poster 1.png";
import img4 from "../image/ry.png";
import videoFile from "../image/Reel 1.mp4";

const ImageCategory1 = () => {
 

  // Ensure that the page starts at the top when this component renders
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <div className=" md:px-20 lg:mt-24   ">
      <div className=" md:hidden" style={{ backgroundColor: "#2C041E" }}>
        <div className="flex xs:mt-16">
          <img className=" w-44 ml-4" src={img1} alt="Work" />
          <h1 className=" mt-10 text-white" style={{ fontSize: "1.1em" }}>
          Offline To Online Marketing
          </h1>
        </div>
        <p className=" px-6 py-3 text-white">Transform your business from offline to online and reach a global audience with ease. Let's take your success to the next level!</p>
      </div>

      <div className="md:grid hidden mt-32 sm:block md:grid-cols-2">
        <img
          className="h-72 ml-12 w-[400px]"
          src={img1}
          alt="Work"
        />
        <div className=" md:mt-16 md:-ml-20">
          <h1 className="font-bold md:-ml-10 md:text-black text-white md:text-3xl">
          Offline To Online Marketing
          </h1>
          <li className=" list-disc  md:text-black text-white md:text-xl md:px-32 md:-ml-36 md:py-1">
          Transform your business from offline to online and reach a global audience with ease. Let's take your success to the next level!
          </li>
        </div>
      </div>

   
        <h1
          className="font-bold text-center md:-mt-7 md:ml-20 mt-2  text-2xl md:text-3xl" style={{ color: '#2C041E' }}
        >
          Our Client
        </h1>
        <img className=" md:hidden w-32 mt-4 ml-36 " src={img2} alt="" />
        <p
          className='font-bold hidden sm:block py-1 px-52' style={{ color: '#2C041E', fontSize: '1.2em' }}
        >
          Ramani , (Hindi Tution , Youtube & Social media marketing, more follower, Spoken Hindi, Hindi exams)
        </p>
        <p
          className='font-bold md:hidden px-9 mt-3' style={{ color: '#2C041E', fontSize: '1 em' }}
        >
          Ramani , (Hindi Tution , Youtube & Social media marketing, more follower, Spoken Hindi, Hindi exams)
        </p>
        <div className="md:flex md:ml-32 md:px-20 px-9 md:py-2'">
          <p className=" md:mt-0 mt-2  md:text-xl">
            Passionate towards teaching, Creative thinker, students friendly,
            can guide students for their better performance. Friendly and
            approachable to the students and maintains a safe, organised and
            comfortable environment. Better learning place and carve a niche of
            a learning system, which not just imparts bookish knowledge to
            students but trains them to be successful individuals in life. I
            have developed thorough expertise in handling Hindi classes for
            Prachar Sabha exams in Offline with personal care. In Online we
            conduct spoken Hindi classes in a simple way. Through YouTube there
            are recorded classes, and social media marketing has been made to
            raise awareness of the additional language.
          </p>
          <img
            className="md:w-36 hidden sm:block -mt-7 md:h-36 md:ml-10"
            src={img2}
            alt=""
          />
        </div>
  

      <h1 className="text-center py-2 text-2xl font-bold">Our Client Works</h1>

      <div className="md:grid md:grid-cols-3 md:px-48 md:mt-0 mt-3 md:ml-40 md:py-5">
        <div>
          <img className="w-56 h-60 md:ml-0 ml-20" src={img3} alt="" />
          <p className="font-semibold hidden sm:block md:ml-20">Poster</p>
          <p className="font-semibold text-center md:hidden">Poster</p>
        </div>
        <div>
          <video className="w-1/2 md:mt-0 md:ml-0 ml-24 mt-3 h-60" controls>
            <source src={videoFile} type="video/mp4" />
          </video>
          <p className="font-semibold hidden sm:block md:ml-12">Reel</p>
          <p className="font-semibold md:hidden text-center">Reel</p>
        </div>
        <div>
          <a href="https://www.youtube.com/watch?v=RzsQ10VwKE0">
            <img
              className="w-56 md:-ml-20 md:mt-0 mt-3 ml-20 h-60"
              src={img4}
              alt="YouTube"
            />
          </a>
          <p className="font-semibold hidden sm:block md:ml-0">YouTube</p>
          <p className="font-semibold text-center md:hidden">YouTube</p>
        </div>
      </div>
    </div>
  );
};

export default ImageCategory1;



