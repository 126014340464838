import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import img2 from "../image/Logo.png";
import { useMenuContext } from "./MenuContext";

const Header = () => {
  const { isMenuOpen, toggleMenu, activeSection, handleSetActive } = useMenuContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [headerOffset, setHeaderOffset] = useState(140);

  // Update headerOffset based on screen width
  useEffect(() => {
    const updateHeaderOffset = () => {
      setHeaderOffset(window.innerWidth < 768 ? 110 : 140); // Mobile: 110, Desktop: 140
    };

    updateHeaderOffset(); // Set on component mount
    window.addEventListener("resize", updateHeaderOffset); // Update on resize

    return () => {
      window.removeEventListener("resize", updateHeaderOffset); // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = ["home", "about", "work", "testimonials", "team", "services", "news", "talk"];
      for (let i = 0; i < sections.length; i++) {
        const section = document.getElementById(sections[i]);
        if (section) {
          const rect = section.getBoundingClientRect();
          if (rect.top < headerOffset && rect.bottom > headerOffset) {
            handleSetActive(sections[i]);
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [headerOffset, handleSetActive]);

  const handleNavigation = (section) => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => scrollToSection(section), 200);
    } else {
      scrollToSection(section);
    }
  };

  const scrollToSection = (section) => {
    const element = document.getElementById(section);
    if (element) {
      const yOffset = -headerOffset;
      const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
      handleSetActive(section);
    }
  };

  return (
    <>
      <header className=" w-full h-[70px] sm:h-24 md:h-24 lg:h-32 xl:h-[130px] 2xl:h-[140px]  fixed top-0 bg-[#2C041E] z-10">
        <div className="flex justify-end items-center fixed sm:-ml-5">
          <ScrollLink to="home" className=" items-center">
            <img
              src={img2}
              alt="Logo"
              className="w-[46px] h-[50px] sm:w-[60px] sm:h-[65px] lg:w-[75px]  xl:ml-[75px] lg:h-[80px] mt-3 sm:mt-3 md:mt-3 lg:-mt-2 xl:mt-0 ml-4  sm:ml-10 "
            />
          </ScrollLink>
          <p className="ml-2 mt-3 font-bold text-[9px] sm:text-[15px] lg:mt-[100px] lg:ml-[-5rem] xl:ml-[-5.5rem] lg:text-[12px] text-[#FFBE2A] flex">
            SAMRA DIGIMECH<span className="-mt-2 ">&reg;</span>
          </p>

          <div className=" md:hidden hidden  xl:block lg:block 2xl:block">
            <nav className="font-bold py-4">
              <div className="flex lg:ml-[180px] lgl:ml-[320px] xl:ml-[390px] xxl:ml-[540px] 2xl:ml-[640px] 3xl:ml-[1000px] 4xl:ml-[1650px]  mt-[23px]">
                <div className="md:flex gap-7 text-[#F09819]">
                  <button
                    onClick={() => handleNavigation("home")}
                    className={`px-2 py-1 border-b-2 ${
                      activeSection === "home"
                        ? "border-current "
                        : "border-transparent"
                    } `}
                  >
                    Home
                  </button>
                  <button
                    onClick={() => handleNavigation("about")}
                    className={`px-2 py-1 border-b-2 ${
                      activeSection === "about"
                        ? "border-current"
                        : "border-transparent"
                    } hover:border-current`}
                  >
                    About Us
                  </button>
                  <button
                    onClick={() => handleNavigation("work")}
                    className={`px-2 py-1 border-b-2 ${
                      activeSection === "work"
                        ? "border-current"
                        : "border-transparent"
                    } hover:border-current`}
                  >
                    Our Work
                  </button>
                  <button
                    onClick={() => handleNavigation("testimonials")}
                    className={`px-2 py-1 border-b-2 ${
                      activeSection === "testimonials"
                        ? "border-current"
                        : "border-transparent"
                    } hover:border-current`}
                  >
                    Testimonials
                  </button>
                  <button
                    onClick={() => handleNavigation("team")}
                    className={`px-2 py-1 hidden border-b-2 ${
                      activeSection === "team"
                        ? "border-current"
                        : "border-transparent"
                    } hover:border-current`}
                  >
                    Team
                  </button>
                  <button
                    onClick={() => handleNavigation("services")}
                    className={`px-2 py-1 border-b-2 ${
                      activeSection === "services"
                        ? "border-current"
                        : "border-transparent"
                    } hover:border-current`}
                  >
                    Services
                  </button>
                  <button
                    onClick={() => handleNavigation("news")}
                    className={`px-2 py-1 border-b-2 ${
                      activeSection === "news"
                        ? "border-current"
                        : "border-transparent"
                    } hover:border-current`}
                  >
                    News
                  </button>
                  <button
                    onClick={() => handleNavigation("talk")}
                    className="px-3 py-1 border-2 border-[#F09819] rounded-full "
                  >
                    Let's Talk
                  </button>
                </div>
              </div>
            </nav>
          </div>
          <div className=" -ml-5 text-black font-bold" onClick={toggleMenu}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className=" xl:hidden lg:hidden block sm:block md:block text-[#FFBE2A] w-9 h-9  ml-[120px] xs:ml-[140px] xss:ml-[190px] xsss:ml-[240px] sm:w-12 sm:h-12 sm:ml-[350px] md:ml-[650px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </div>
        </div>
        <nav
          className={` font-bold mt-12 py-4 px-4 bg-[#2C041E] text-[#FFBE2A] ${
            isMenuOpen ? "block" : "hidden"
          }`}
          style={{ zIndex: 100 }}
        >
          <div className="flex flex-col items-start lg:ml-0 ">
            <div className="grid grid-rows-7  py-4 ">
              <button
                onClick={() => handleNavigation("home")}
                className={`px-2 py-1 border-b-2 ${
                  activeSection === "home"
                    ? "border-current"
                    : "border-transparent"
                } hover:border-current`}
              >
                Home
              </button>
              <button
                onClick={() => handleNavigation("about")}
                className={`px-2 py-1 border-b-2 ${
                  activeSection === "about"
                    ? "border-current"
                    : "border-transparent"
                } hover:border-current`}
              >
                About Us
              </button>
              <button
                onClick={() => handleNavigation("work")}
                className={`px-2 py-1 border-b-2 ${
                  activeSection === "work"
                    ? "border-current"
                    : "border-transparent"
                } hover:border-current`}
              >
                Our Work
              </button>
              <button
                onClick={() => handleNavigation("testimonials")}
                className={`px-2 py-1 border-b-2 ${
                  activeSection === "testimonials"
                    ? "border-current"
                    : "border-transparent"
                } hover:border-current`}
              >
                Testimonials
              </button>
              <button
                onClick={() => handleNavigation("team")}
                className={`px-2 py-1 hidden border-b-2 ${
                  activeSection === "team"
                    ? "border-current"
                    : "border-transparent"
                } hover:border-current`}
              >
                Team
              </button>
              <button
                onClick={() => handleNavigation("services")}
                className={`px-2 py-1 border-b-2 ${
                  activeSection === "services"
                    ? "border-current"
                    : "border-transparent"
                } hover:border-current`}
              >
                Services
              </button>
              <button
                onClick={() => handleNavigation("news")}
                className={`px-2 py-1 border-b-2 ${
                  activeSection === "news"
                    ? "border-current"
                    : "border-transparent"
                } hover:border-current`}
              >
                News
              </button>
              <button
                onClick={() => handleNavigation("talk")}
                smooth={true}
                duration={500}
                spy={true}
                offset={-70}
                onSetActive={() => handleSetActive("talk")}
                className="px-3 py-1"
              >
                Let's Talk
              </button>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
