import React, { useState } from 'react'
import { Link as ScrollLink } from 'react-scroll';
import img1 from '../image/Logo.png'
import img2 from '../image/Vector 4.png'
import img3 from '../image/Vector 5.png'
import img4 from '../image/Vector 6.png'
import img5 from '../image/Vector 7.png'
import img6 from '../image/Vectro (1).png'
import img7 from '../image/Vectro (2).png'
import img8 from '../image/house.png'
import FormComponent from './FormComponent';



const Footer = () => {
  const [activeSection, setActiveSection] = useState(null);
  const handleSetActive = (section) => {
    setActiveSection(section);
  };

  return (
    <div className=' w-full bg-[#2C041C]' >
      <div className=' md:px-8  md:grid grid-cols-4'>
        <div className='mt-4 lg:mt-12 xl:ml-[55px] 2xl:ml-[130px] hidden md:block lg:block  sm:hidden '>
          <img className='md:w-[56px] md:h-[60px] lg:w-[70px] lg:h-[80px] xl:w-[75px] xl:ml-[10px] md:ml-6 w-16 h-16 ' src={img1} alt='logo' />
          <p className='font-bold text-[#FFBE2A] text-[12px] flex'>SAMRA DIGIMECH <span className='md:-mt-2'>&reg;</span></p>
        </div>

        <div className=' flex md:hidden  '>
          <div className='ml-4 mt-4'>
            <img className=' w-[46px] h-[50px] xs:mx-6 sm:w-[57px] sm:h-[60px] sm:mt-4 sm:ml-[30px]'  src={img1} alt='logo' />
            <p className='font-bold flex  text-[#FFBE2A] text-[10px] sm:text-[10px]'>SAMRA DIGIMECH <span className='-mt-1'>&reg;</span></p>
          </div>
          <div className='mt-8 sm:mt-14'>
            <ScrollLink to="talk" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('talk')} className="border xs:text-[14px] xs:ml-32 xss:text-[16px] xss:ml-44 xsss:ml-48 sm:text-[17px]  px-2 py-1 rounded-full" style={{ borderColor: '#FFBE2A', color: '#FFBE2A' }} >Let's Talk</ScrollLink>
          </div>
        </div>
        <p className=' md:hidden mt-3 sm:text-[21px] xs:text-[16px] xsss:text-[18px] font-semibold text-center text-[#FFBE2A]'>We expertise your expectations</p>
        <div>
          <hr className="w-0.5 h-36 lg:ml-[-100px] 2xl:ml-[-70px] lg:mt-[40px] hidden   sm:hidden xl:block lg:block 2xl:blocks mt-12 border-[#FFBE2A] border-[1px] " style={{  borderRadius: '21px' }} />
        </div>
        <div className=' md:ml-[-400px] lg:ml-[-370px] xxl:ml-[-450px] 2xl:ml-[-470px] md:mt-7 ' >
          <div className='md:grid md:grid-cols-2  text-[#FFBE2A] py-3'>
            <div className='md:flex xs:gap-4 xs:px-5  md:px-12 md:mt-16 2xl:px-20 lg:mt-0'>
            <div className=' '>
              <div className='flex xs:mb-3 xs:gap-5 xss:gap-16 xsss:gap-20 xs:mt-3'>
              <ul className='xss:text-[17px] xsss:text-[17px]' >
              <h1 className='font-bold xss:text-[19px] xsss:text-[20px] xl:text-[18px]  sm:text-[21px] md:text-xl' >Menu</h1>
                <li><ScrollLink to="home" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('home')} className={`  xl:text-[15px] sm:text-[18px] ${activeSection === "home" ? "" : ""} `}>Home</ScrollLink></li>
                <li><ScrollLink to="about" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('about')} className={`xl:text-[15px] sm:text-[18px] ${activeSection === "about" ? "" : ""} `}>About us</ScrollLink></li>
                <li><ScrollLink to="work" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('work')} className={` xl:text-[15px] sm:text-[18px] ${activeSection === "work" ? "" : ""} `}>Our Work</ScrollLink></li>
                <li><ScrollLink to="testimonials" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('testimonials')} className={` xl:text-[15px] sm:text-[18px] ${activeSection === "testimonials" ? "" : ""}`}>Testimonials</ScrollLink></li>
              </ul>
              <ul className=' flex gap-3  md:hidden'>
              <a href='https://www.instagram.com/samradigimech/'><img className='w-7 h-7  ' src={img2} alt='' /></a>
            <a href='https://www.facebook.com/samradigimech'><img className='w-7 h-7  ' src={img5} alt='' /></a>
            <a href='https://in.pinterest.com/samradigimech/'><img className='w-7 h-7 ' src={img4} alt='' /></a>
            <a href='https://x.com/SamraDigimech'><img className='w-7 h-7 ' src={img3} alt='' /></a>
              </ul>
              </div>
            </div>
            <div className='  md:mt-3  lg:ml-[20px] xl:ml-[50px] xxl:ml-[70px] 2xl:ml-[80px] '>
              <h1 className='font-bold md:text-xl xss:text-[19px] xsss:text-[20px] xl:text-[18px]   sm:text-[21px] ' >Others</h1>
              <ul className='xss:text-[16px] xsss:text-[17px]'>
                <li><ScrollLink to="team" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('team')} className={` xl:text-[15px] md:text-[18px] sm:text-[18px] ${activeSection === "team" ? "" : ""} `}>Team</ScrollLink></li>
                <li><ScrollLink to="services" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('services')} className={` xl:text-[15px] md:text-[18px] sm:text-[18px] ${activeSection === "services" ? "" : ""}`}>Services</ScrollLink></li>
                <li><ScrollLink to="news" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('news')} className={` xl:text-[15px] md:text-[18px] sm:text-[18px] ${activeSection === "news" ? "" : ""}`}>News</ScrollLink></li>
                <li><ScrollLink to="talk" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('talk')} className="hidden xl:text-[15px] md:text-[18px] sm:hidden md:block lg:block" >Let's Talk</ScrollLink></li>
              </ul>
            </div>
            </div>
            <div className='sm:mt-3 md:mt-0 '>
              <ul className='xl:-ml-5'>
                <li className='font-bold mt-3 xs:mx-5 xs:text-[16px] xss:text-[19px]  md:text-xl md:mx-4 '>Contact Us</li>

                <div className='md:block lg:block xl:block 2xl:block  sm:flex '>
                  <div className='xs:flex md:block '>
                <div className='xs:mx-5  md:mx-0 md:mt-1   '>
                  <a className=' text-sm mt-1 flex gap-1 xs:text-[12px] xss:text-[15px] xsss:text-[16px] sm:text-[18px] xl:text-[15px]' href='tel:+916383710401'><img className='w-4 h-4 xss:w-5 xss:h-5 md:w-6 md:h-6  mt-1' src={img6} alt='' />+916383710401</a>
                </div>
                <div className=' xs:-ml-2  md:mx-0 mt-1 md:mt-1 '>
                  <a className=' text-sm flex gap-1 xs:text-[12px] xss:text-[15px] xsss:text-[16px] sm:text-[18px] xl:text-[15px]' href='mailto:samradigimech@gmail.com'><img className='w-4 h-4 xss:w-5 xss:h-5 md:w-6 md:h-6  ' src={img7} alt='' />samradigimech@gmail.com</a>
                </div>
                </div>
                </div>
                <div className=' xs:mx-5  md:mx-0 xl:mr-16 2xl:mr-24  md:mt-1 mt-1'>
                  
                  <p  className=' flex gap-1 md:px-0 xs:text-[12px] xss:text-[15px] xsss:text-[16px] text-sm sm:text-[18px] xl:text-[15px] '><img className='md:w-6 w-4 h-4 xss:w-5 xss:h-5  md:h-6' src={img8} alt='' />No 77, Gajalakshmi nagar, 3rd Street, Nolambur, Chennai 95.</p>
                </div>

              </ul>
            </div>
          </div>
        </div>
        
        <div>
          <div className='md:flex xs:mx-20 xss:mx-28 hidden md:block md:mb-2 md:mx-0 md:mt-16 lg:mt-7  xl:mx-12 py-3 '>
            <a href='https://www.instagram.com/samradigimech/'><img className='w-7 h-7 ' src={img2} alt='' /></a>
            <a href='https://www.facebook.com/samradigimech'><img className='w-7 h-7 ml-3' src={img5} alt='' /></a>
            <a href='https://in.pinterest.com/samradigimech/'><img className='w-7 h-7 ml-3' src={img4} alt='' /></a>
            <a href='https://x.com/SamraDigimech'><img className='w-7 h-7 ml-3' src={img3} alt='' /></a>
          </div>
          <FormComponent/>
        </div>
      </div>
      <hr className="md:w-5/6  sm:block md:ml-12 lg:ml-[90px]  2xl:ml-[130px] border-[1px] border-[#FFBE2A]  " style={{ borderRadius: '21px' }} />
      <div>
        <p className="xl:text-[15px] py-7  font-semibold md:font-bold sm:text-[18px] xs:text-[14px] xs:mx-4 xss:mx-9 md:mx-44 lg:mx-24 text-[#FFBE2A] flex"  >&copy; {new Date().getFullYear()} Samra Digimech<span className=' w-[8px] -mt-2 '>&reg;</span>. All rights reserved</p>
      </div>
    </div>
  )
}

export default Footer